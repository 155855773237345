// @ts-nocheck
import * as React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import "./popup.css";
import { API_URL, BOOK_ACCESS } from "../../utils/constants";

const CancelSubscriptionPopUp = ({
  handleCancelSubPopupVisibility,
  userStripeId,
}: any) => {
  const handleCloseClick = () => {
    handleCancelSubPopupVisibility(false);
  };

  const cancelSubscription = async (userStripeId: any) => {
    // await fetch(`${API_URL}/api/cancel-subscription`, {
    //   method: "POST",
    //   headers: { "content-type": "application/json" },
    //   body: JSON.stringify({ userStripeId, bookToAccess: BOOK_ACCESS }),
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     alert(responseJson.body.message);
    //   });
  };

  return (
    <Modal show={true} className="profileModal">
      <Modal.Header>
        <Modal.Title>Afmeld abonnement</Modal.Title>
        <button
          onClick={handleCloseClick}
          type="button"
          className="btn btn-secondary commonBtn"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <p>Er du sikker på du vil afmelde dit abonnement?</p>
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn btn-secondary commonBtn"
              onClick={() => cancelSubscription(userStripeId)}
            >
              Bekræft
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelSubscriptionPopUp;
