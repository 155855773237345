export const CHAPTERS = [
  {
    id: 1,
    title: "1. Retsstaten",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1436450412740-6b988f486c6b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDV8fGRhbmlzaCUyMGxhd3xlbnwwfHx8fDE2MjA2ODg1ODM&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 1 om retsstaten skal kun læses i hovedtræk. Kapitlet omhandler vores demokrati og tremagtsdelingen mellem den dømmende magt (Domstolene), den udøvende magt (Regeringen) og den lovgivende magt (Folketinget).",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },

  {
    id: 2,
    title: "2. Retskilderne",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1481627834876-b7833e8f5570?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDJ8fGJvb2tzfGVufDB8fHx8MTYyMDY4NzYzNA&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 2 skal kun læses i hovedtræk. Kapitlet omhandler hvilke retsregler (retskilder) som findes i vores retssystem og hvordan retskilderne anvendes i den juridiske metode.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },

  {
    id: 3,
    title: "3. Aftaleret",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1521791136064-7986c2920216?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fGFncmVlbWVudHxlbnwwfHx8fDE2MjA2ODk2NjI&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 3 omhandler regler for indgåelse af aftaler, om deres ugyldighed samt deres fortolkning.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },

  {
    id: 4,
    title: "4. Fuldmagter og mellemmænd",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1603796846097-bee99e4a601f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fGRvY3VtZW50JTIwc2lnbmluZ3xlbnwwfHx8fDE2MjE1MDkwNjM&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 4 omhandler reglerne for fuldmagt. Fuldmagt er en ret til at handle i en andens navn og for en andens regning.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
  {
    id: 5,
    title: "5. Forbrugeraftaler og forbrugerbeskyttelse",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1596609548086-85bbf8ddb6b9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDd8fGNvbnN1bWVyfGVufDB8fHx8MTYyMDY4Nzk4Nw&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 5 omhandler lovreglerne for beskyttelse af forbrugerne ved deres køb af vare og tjenesteydelser på markedet.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
  {
    id: 6,
    title: "6. Erstatnings-, forsikrings- og compliancereglerne",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1441804238730-210ce1c2cc00?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fGJyb2tlbnxlbnwwfHx8fDE2MjA3MTQ1NDk&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 6 omhandler, hvilke betingelser, der skal være opfyldt for, at man pådrager sig et erstatningsansvar.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
  {
    id: 7,
    title: "7. Køb",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1556741568-055d848f8bfd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDJ8fHB1cmNoYXNlfGVufDB8fHx8MTYyMDY4Nzk1MA&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: 'Kapitel 7 omhandler reglerne for køb. Med "Køb" forstås traditionelt som en aftale mellem sælger og køber om overdragelse af ejendomsretten af et formuegode (løsøre) mod et vederlag i penge.',
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
   {
    id: 8,
    title: "8. Virksomhedsformer og hæftelser",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1462206092226-f46025ffe607?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDV8fGNvbXBhbnl8ZW58MHx8fHwxNjIwNjg2MzU3&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 8 omhandler reglerne i selskabsretten, der beskæftiger sig med retsforholdene hos aktieselskaber, anpartsselskaber, fonde, foreninger og andre juridiske personer",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
   {
    id: 9,
    title: "9. Insolvensret",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1545113151-992ca8adf512?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDE0fHxwb29yfGVufDB8fHx8MTYyMDY4ODAyMw&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 9 omhandler insolvensreglerne som anvendes i dagligdagen i ejendomshandel, ejendomsadministration, bank og realkredit, forsikring samt revision og økonomifunktion i forbindelse med at kunderne og klienter skylder penge til jeres virksomhed.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
   {
    id: 10,
    title: "10. Kreditaftaler",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1612795459707-1002f77720d2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDV8fGNyZWRpdGNhcmR8ZW58MHx8fHwxNjIwNjg4MDcx&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 10 omhandler reglerne for kreditaftaler og kreditkøb, når du aftaler med en køber, at vedkommende ikke behøver at betale kontant, men kan betale en del af eller hele prisen for den vare, han/hun har købt, på et senere tidspunkt.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
   {
    id: 11,
    title: "11. Kautionsforhold",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1561414927-6d86591d0c4f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDF8fG1vbmV5fGVufDB8fHx8MTYyMDY5MDAwMA&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 11 omhandler reglerne for kautionsforhold. Kaution er kendetegnet ved, at en person eller virksomhed, går ind og garanterer at betale en anden persons gæld.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
   {
    id: 12,
    title: "12. Fordringer, gældsbreve og pantebreve",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1610569244414-5e7453a447a8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDV8fG1vcnRnYWdlJTIwZGVlZHxlbnwwfHx8fDE2MjA2ODgxMzU&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 12 omhandler reglerne omkring fordringer. En fordring er et krav, som en person eller et selskab (kreditor) har mod en anden part (debitor).",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
   {
    id: 13,
    title: "13. Ophør af fordringer",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1509470578905-4a29e9a1a4d9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDE1fHxiYW5raW5nfGVufDB8fHx8MTYyMDcxNDI1Nw&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 13 omhandler reglerne for fordringers ophør ved bl.a. modregning, passivitet og forældelse.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
   {
    id: 14,
    title: "14. Pant i fast ejendom, løsøre og virksomhedspant",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1539039374392-54032a683b1d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDd8fGxvY2t8ZW58MHx8fHwxNjIwNjg2MDg3&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 14 omhandler lovreglerne i tinglysningsloven for pant i fast ejendom, løsøre og virksomhedspant.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
   {
    id: 15,
    title: "15. Tingsretlige konflikter",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1602058176018-7915d6bd37ae?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDF8fGNvbmZsaWN0fGVufDB8fHx8MTYyMDY4OTgwNg&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 15 omhandler de tingsretlige konflikter ved bl.a. dobbeltsalg og kædeoverdragelser.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
   {
    id: 16,
    title: "16. Handel med fast ejendom",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1426122402199-be02db90eb90?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fHJlYWx8ZW58MHx8fHwxNjIwNzE0MzE3&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 16 omhandler reglerne for køb og salg af fast ejendom. Især anvendelig for dig, der skal arbejde i ejendomshandel, ejendomsadministration, bank og realkredit, forsikring, samt revision og økonomifunktion, ved din rådgivning til køberen og sælgeren ved finansiering af fast ejendom mv.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
   {
    id: 17,
    title: "17. Familieret",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1470843810958-2da815d0e041?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDM0fHxmYW1pbHklMjBkaXNwdXRlfGVufDB8fHx8MTYyMDY4NjMwNQ&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 17 omhandler reglerne for ægtefællers økonomiske forhold.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
   {
    id: 18,
    title: "18. Arveretten",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1522752622479-65eefd320386?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDJ8fGZ1bmVyYWx8ZW58MHx8fHwxNjIwNTA2ODAx&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Kapitel 18 omhandler reglerne om arv, som kan være anvendelig for dig, som privat person gifte, som samlevende, og dig som finansøkonom.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
   {
    id: 19,
    title: "19. Lovsamling",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1473755504818-b72b6dfdc226?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDUxfHxib29rc3xlbnwwfHx8fDE2MjA2ODg3MDM&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Her er en samling af de relevante love.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
   {
    id: 20,
    title: "20. Vejledning til løsning af eksamensopgaver i Erhvervs- og Finansjura",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1596496050755-c923e73e42e1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDE3fHxleGFtfGVufDB8fHx8MTYyMDY4OTYwOA&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Hvordan løser du bedst muligt eksamensopgaver.",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
   {
    id: 21,
    title: "21. Podcasts",
    content: ``,
    image:
      "https://images.unsplash.com/photo-1485579149621-3123dd979885?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fHBvZGNhc3R8ZW58MHx8fHwxNjI1NzM2Mjc4&ixlib=rb-1.2.1&q=80&w=2000",
    excerpt: "Her er en oversigt over tilgængelige podcasts",
    authorName: "Sonny Kristoffersen",
    authorImage:
      "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
    tags: "Free, Jura",
  },
  //  {
  //   id: 22,
  //   title: "22 Test",
  //   content: ``,
  //   image:
  //     "https://images.unsplash.com/photo-1423666523292-b458da343f6a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fHN0b2NrJTIwcHJpY2VzfGVufDB8fHx8MTYyNjQ2Mjk3MQ&ixlib=rb-1.2.1&q=80&w=2000",
  //   // excerpt: "Hvad skal Apple aktien koste imorgen?",
  //   authorName: "Sonny Kristoffersen",
  //   authorImage:
  //     "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
  //   tags: "Free, Jura",
  // },
  //  {
  //   id: 23,
  //   title: "23. Podcasts",
  //   content: ``,
  //   image:
  //     "https://images.unsplash.com/photo-1485579149621-3123dd979885?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fHBvZGNhc3R8ZW58MHx8fHwxNjI1NzM2Mjc4&ixlib=rb-1.2.1&q=80&w=2000",
  //   // excerpt: "Her kan du høre en podcast-serie, omkring statistik",
  //   authorName: "Sonny Kristoffersen",
  //   authorImage:
  //     "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
  //   tags: "Free, Jura",
  // },
  //  {
  //   id: 24,
  //   title: "24. Materialer",
  //   content: ``,
  //   image:
  //     "https://images.unsplash.com/photo-1562654501-a0ccc0fc3fb1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDIzfHxwYXBlcnN8ZW58MHx8fHwxNjI2NjEwNTQ1&ixlib=rb-1.2.1&q=80&w=2000",
  //   // excerpt: "Alle materialer i bogen er samlet her.",
  //   authorName: "Sonny Kristoffersen",
  //   authorImage:
  //     "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
  //   tags: "Free, Jura",
  // },
  //  {
  //   id: 25,
  //   title: "25. Ordliste",
  //   content: ``,
  //   image:
  //     "https://images.unsplash.com/reserve/uZYSV4nuQeyq64azfVIn_15130980706_64134efc6e_o.jpg?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDMwfHx3b3Jkc3xlbnwwfHx8fDE2MjY2NDM5OTM&ixlib=rb-1.2.1&q=80&w=2000",
  //   // excerpt: "Her er en oversigt over begreber",
  //   authorName: "Sonny Kristoffersen",
  //   authorImage:
  //     "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
  //   tags: "Free, Jura",
  // },
  // {
  //   id: 26,
  //   title: "26. Ordliste",
  //   content: ``,
  //   image:
  //     "https://images.unsplash.com/reserve/uZYSV4nuQeyq64azfVIn_15130980706_64134efc6e_o.jpg?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDMwfHx3b3Jkc3xlbnwwfHx8fDE2MjY2NDM5OTM&ixlib=rb-1.2.1&q=80&w=2000",
  //   // excerpt: "Her er en oversigt over begreber",
  //   authorName: "Sonny Kristoffersen",
  //   authorImage:
  //     "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
  //   tags: "Free, Jura",
  // },
  // {
  //   id: 27,
  //   title: "27. Vis network example",
  //   content: ``,
  //   image:
  //     "https://images.unsplash.com/photo-1485579149621-3123dd979885?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fHBvZGNhc3R8ZW58MHx8fHwxNjI1NzM2Mjc4&ixlib=rb-1.2.1&q=80&w=2000",
  //   // excerpt: "Hvilke respondenter ligner hinanden?",
  //   authorName: "Sonny Kristoffersen",
  //   authorImage:
  //     "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
  //   tags: "Free, Jura",
  // },
  // {
  //   id: 28,
  //   title: "28. Tooltip",
  //   content: ``,
  //   image:
  //     "https://images.unsplash.com/photo-1485579149621-3123dd979885?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fHBvZGNhc3R8ZW58MHx8fHwxNjI1NzM2Mjc4&ixlib=rb-1.2.1&q=80&w=2000",
  //   // excerpt: "Hvilke respondenter ligner hinanden?",
  //   authorName: "Sonny Kristoffersen",
  //   authorImage:
  //     "https://storage.googleapis.com/tepedu/juraimages/Billede-af-Sonny-2021.png",
  //   tags: "Free, Jura",
  // },
];
