// @ts-nocheck
import React, { useEffect, useState } from "react";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/auth.css";
import Cookies from "universal-cookie";
import { loadStripe } from "@stripe/stripe-js";
import { API_URL, BOOK_ACCESS, SIGN_IN_SESSION_COOKIE } from "../../utils/constants";
import Header from "../../components/header";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    if (cookies.get(SIGN_IN_SESSION_COOKIE)) {
      window.location.href = "/";
    }
  }, []);

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event: any) {
    event.preventDefault();
    setShowMessage(false);
    setIsFormSubmitted(true);
    if (password.length < 6) {
      setMessageColor("red");
      setMessage("Password længde skal være mindst seks.");
      setShowMessage(true);
      setIsFormSubmitted(false);
      return;
    }
    // await fetch(`${API_URL}/api/change-password`, {
    //   method: "POST",
    //   headers: { "content-type": "application/json" },
    //   body: JSON.stringify({ email, password, bookToAccess: BOOK_ACCESS }),
    // })
    //   .then(async (response) => response.json())
    //   .then(async (responseJson) => {
    //     if (responseJson.body.error == "1") {
    //       setMessageColor("red");
    //       setMessage(responseJson.body.message);
    //       setIsFormSubmitted(false);
    //     } else {
    //       setMessageColor("green");
    //       setMessage(responseJson.body.message);
    //       setTimeout(function () {
    //         window.location.href = "/login";
    //       }, 3000);
    //     }
    //     setShowMessage(true);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  }

  return (
    <>
      <Header />
      <div className="form-div customFormDiv">
        <form onSubmit={handleSubmit}>
          <h1 className="page-title">Glemt Password</h1>

          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Skriv email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isFormSubmitted}
            />
          </div>

          <div className="form-group">
            <label>Nyt Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Skriv nyt password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              maxLength={12}
              disabled={isFormSubmitted}
            />
          </div>

          <button
            type="submit"
            disabled={!validateForm() || isFormSubmitted}
            className="btn btn-secondary commonBtn marginTop_five"
          >
            Skift Password
          </button>
          {showMessage ? (
            <p className="message" style={{ color: messageColor }}>
              {message}
            </p>
          ) : null}
        </form>
      </div>
    </>
  );
}
