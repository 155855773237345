import * as React from "react";
import { useContext } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "../App.css";
import { UserContext } from "../context/UserContext";
import { CHAPTERS } from "../data/chapters";
import { API_URL, BOOK_ACCESS } from "../utils/constants";
import CancelSubscriptionPopUp from "./popups/cancelSubscriptionPopup";
import ProfilePopUp from "./popups/profile-popup";
import UpdateCardPopUp from "./popups/updateCardPopup";
import FilterResults from "react-filter-search";

declare global {
  interface Window {
    find: any;
  }
}

const Header = () => {
  const { userData, userDataLoaded, userLogout } = useContext(UserContext);
  const [userPaymentData, setUserPaymentData] = React.useState({});
  const [userPaymentInvoice, setUserPaymentInvoice] = React.useState("");
  const [userLatestPaymentInvoiceDate, setUserLatestPaymentInvoiceDate] =
    React.useState("");
  const [showProfilePopup, setShowProfilePopup] = React.useState(false);
  const [showCancelSubPopup, setShowCancelSubPopup] = React.useState(false);
  const [showCardUpdatePopup, setShowCardUpdatePopup] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [isMonthlySubscription, setIsMonthlySubscription] =
    React.useState(false);
  const [
    isMonthlySubscriptionButCurrentZero,
    setIsMonthlySubscriptionButCurrentZero,
  ] = React.useState(false);
  let postPath = "";
  if (typeof window !== "undefined") {
    postPath = window.location.pathname;
  }
  React.useEffect(() => {
    if (userData.user_id) {
      if (userData.plan_id == 0 && userData.user_subscription_start) {
        setIsMonthlySubscription(true);
        setIsMonthlySubscriptionButCurrentZero(true);
      } else if (userData.plan_id == 1) {
        setIsMonthlySubscription(true);
      } else if (userData.plan_id == 2) {
        setIsMonthlySubscription(true);
      } else if (userData.plan_id == 3) {
        setIsMonthlySubscription(true);
      }
      if (userData.stripe_id) {
        // getUserPaymentMethod();
        // getUserInvoices();
      }
    }
  }, [userData]);
  // const getUserPaymentMethod = async () => {
  //   await fetch(`${API_URL}/api/customer-payment-method`, {
  //     method: "POST",
  //     headers: { "content-type": "application/json" },
  //     body: JSON.stringify({
  //       customerStripeId: userData.stripe_id,
  //       bookToAccess: BOOK_ACCESS,
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       setUserPaymentData(responseJson.body.paymentMethods.data[0].card);
  //     });
  // };
  // const getUserInvoices = async () => {
  //   await fetch(`${API_URL}/api/customer-invoices`, {
  //     method: "POST",
  //     headers: { "content-type": "application/json" },
  //     body: JSON.stringify({
  //       customerStripeId: userData.stripe_id,
  //       bookToAccess: BOOK_ACCESS,
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       setUserPaymentInvoice(
  //         responseJson.body.invoices.data[0].hosted_invoice_url
  //       );
  //       setUserLatestPaymentInvoiceDate(
  //         responseJson.body.invoices.data[0].created
  //       );
  //     });
  // };

  const handleProfilePopupVisibility = (isOpen: boolean) => {
    setShowProfilePopup(isOpen);
  };

  const handleCancelSubPopupVisibility = (isOpen: boolean) => {
    setShowCancelSubPopup(isOpen);
  };

  const handleUpdateCardPopupVisibility = (isOpen: boolean) => {
    setShowCardUpdatePopup(isOpen);
  };

  const handleMainSearchChange = (event: any) => {
    const { value } = event.target;
    setValue(value);
  };

  const handleMainSearchFocus = (event: any) => {
    setTimeout(() => {
      setValue("");
    }, 300);
  };

  const serachInPage = (e: any) => {
    let code = e.keyCode ? e.keyCode : e.which;
    if (code == 13) {
      if (
        typeof window !== "undefined" &&
        !window.find(e.target.value) &&
        e.target.value != ""
      ) {
        alert("No result!");
      }
    }
  };

  return (
    <>
      <Navbar expand="lg" className="header">
        <Container>
          <Navbar.Brand href="/">
            <img src="/tepedu.svg" className="logoImg" width="80" height="37" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown
                title="Indhold"
                id="basic-nav-dropdown"
                className="chapterListBtn"
              >
                {CHAPTERS.map((chapter) => {
                  return (
                    <NavDropdown.Item href={`/kapitel/${chapter.id}`}>
                      {chapter.title}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-center">
            {postPath === "/" && (
              <div>
                <div className="search-container-main">
                  <input
                    className="search-main"
                    id="searchleftmain"
                    type="search test"
                    name="q"
                    placeholder="Search"
                    value={value}
                    onChange={handleMainSearchChange}
                    onBlur={(e) => handleMainSearchFocus(e)}
                  />
                  <label
                    className="button-main searchbutton-main"
                    htmlFor="searchleftmain"
                  >
                    <span className="mglass-main">&#9906;</span>
                  </label>
                </div>
                <FilterResults
                  value={value}
                  data={CHAPTERS}
                  renderResults={(results: any) => (
                    <div
                      className={`searchFilter ${value ? "" : "filterHide"}`}
                    >
                      {results.map((el: any) => (
                        <div>
                          <a href={"/kapitel/" + el.id}>{el.title}</a>
                        </div>
                      ))}
                    </div>
                  )}
                />
              </div>
            )}
            {postPath !== "/" && (
              <div>
                <div className="search-container-main">
                  <input
                    className="search-main"
                    id="searchleft"
                    type="search test"
                    name="q"
                    onKeyUp={(e) => serachInPage(e)}
                    placeholder="Søg i kapitlet"
                  />
                  <label
                    className="button-main searchbutton-main"
                    htmlFor="searchleft"
                  >
                    <span className="mglass-main">&#9906;</span>
                  </label>
                </div>
              </div>
            )}
          </Navbar.Collapse>
          {/* <Navbar.Collapse className="justify-content-end">
            {userDataLoaded && !userData.user_id && (
              <Navbar.Text>
                <a className="loginBtn" href="/login">
                  Login
                </a>
              </Navbar.Text>
            )}
            {userDataLoaded && userData.user_id && (
              <NavDropdown
                title={userData.user_email}
                id="basic-nav-dropdown"
                className="chapterListBtn"
              >
                <NavDropdown.Item
                  onClick={() => handleProfilePopupVisibility(true)}
                >
                  Min konto
                </NavDropdown.Item>
                {isMonthlySubscription && (
                  <>
                  {!isMonthlySubscriptionButCurrentZero && (
                    <NavDropdown.Item
                      onClick={() => handleCancelSubPopupVisibility(true)}
                    >
                      Afmeld abonnement
                    </NavDropdown.Item>
                  )}
                    <NavDropdown.Item
                      onClick={() => handleUpdateCardPopupVisibility(true)}
                    >
                      Skift Kreditkort
                    </NavDropdown.Item>
                  </>
                )}
                <NavDropdown.Item href="#" onClick={userLogout}>
                  Log Out
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
      {showProfilePopup && (
        <ProfilePopUp
          userData={userData}
          userPaymentData={userPaymentData}
          userPaymentInvoice={userPaymentInvoice}
          userLatestPaymentInvoiceDate={userLatestPaymentInvoiceDate}
          isMonthlySubscription={isMonthlySubscription}
          isMonthlySubscriptionButCurrentZero={
            isMonthlySubscriptionButCurrentZero
          }
          handleProfilePopupVisibility={handleProfilePopupVisibility}
        />
      )}
      {showCancelSubPopup && (
        <CancelSubscriptionPopUp
          handleCancelSubPopupVisibility={handleCancelSubPopupVisibility}
          userStripeId={userData.stripe_id}
        />
      )}
      {showCardUpdatePopup && (
        <UpdateCardPopUp
          handleUpdateCardPopupVisibility={handleUpdateCardPopupVisibility}
          customerId={userData.stripe_id}
        />
      )}
    </>
  );
};

export default Header;
