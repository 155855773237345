import * as React from "react";
import Cookies from "universal-cookie";
import {
  API_URL,
  BOOK_ACCESS,
  SIGN_IN_SESSION_COOKIE,
  BOOK_SESSION_USER_IP_COOKIE,
} from "../utils/constants";

export const UserContext = React.createContext<any | null>(null);

const UserProvider = ({ children }: any) => {
  const [todos, setTodos] = React.useState<any[]>([
    {
      id: 1,
      title: "post 1",
      description: "this is a description",
      status: false,
    },
    {
      id: 2,
      title: "post 2",
      description: "this is a description",
      status: true,
    },
  ]);

  const [userData, setUserData] = React.useState<any[]>([]);
  const [userDataLoaded, setUserDataLoaded] = React.useState(false);

  React.useEffect(() => {
    const cookies = new Cookies();
    if (cookies.get(SIGN_IN_SESSION_COOKIE)) {
      //getUser(cookies);
    } else {
      setUserDataLoaded(true);
    }
  }, []);

  // const getUser = (cookies: Cookies) => {
  //   const userEmail = cookies.get(SIGN_IN_SESSION_COOKIE);
  //   fetch(`${API_URL}/api/get-user`, {
  //     method: "POST",
  //     headers: { "content-type": "application/json" },
  //     body: JSON.stringify({ userEmail: userEmail, bookToAccess: BOOK_ACCESS }),
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       if (
  //         responseJson.body.user[0].user_ip ==
  //         cookies.get(BOOK_SESSION_USER_IP_COOKIE)
  //       ) {
  //         //console.log(responseJson.body.user[0]);
  //         setUserData(responseJson.body.user[0]);
  //       } else {
  //         cookies.remove(SIGN_IN_SESSION_COOKIE);
  //         cookies.remove(BOOK_SESSION_USER_IP_COOKIE);
  //       }
  //       setUserDataLoaded(true);
  //     });
  // };

  const userLogout = () => {
    const cookies = new Cookies();
    cookies.remove(SIGN_IN_SESSION_COOKIE);
    cookies.remove(BOOK_SESSION_USER_IP_COOKIE);
    setUserData([]);
    setUserDataLoaded(true);
    window.location.href = "/";
  };

  const saveTodo = (todo: any) => {
    const newTodo: any = {
      id: Math.random(),
      title: todo.title,
      description: todo.description,
      status: false,
    };
    setTodos([...todos, newTodo]);
  };
  const updateTodo = (id: number) => {
    todos.filter((todo: any) => {
      if (todo.id === id) {
        todo.status = true;
        setTodos([...todos]);
      }
    });
  };
  return (
    <UserContext.Provider
      value={{
        todos,
        userData,
        userDataLoaded,
        saveTodo,
        updateTodo,
        userLogout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
