import * as React from "react";
import {
  Col,
  // Container,
  Dropdown,
  // Nav,
  // Navbar,
  // NavDropdown,
  Row,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { CHAPTERS } from "../data/chapters";
import "../styles/footer.css";
import HelpVideoPopUp from "./popups/helpVideoPopup";
// import { BOOK_ACCESS } from "../utils/constants";

const helpImages = [
  "https://storage.googleapis.com/tepedu/helpvideos/koeb.mp4",
  "https://storage.googleapis.com/tepedu/helpvideos/login.mp4",
  "https://storage.googleapis.com/tepedu/helpvideos/passwordglemt.mp4",
  "https://storage.googleapis.com/tepedu/helpvideos/afmeld.mp4",
  "https://storage.googleapis.com/tepedu/helpvideos/skift.mp4",
  "https://mdbootstrap.com/img/Photos/Slides/img%20(19).jpg",
];

const Footer = () => {
  let { id } = useParams();
  const totalPosts = CHAPTERS.length;

  const [helpModalImageSrc, setHelpModalImageSrc] = React.useState("");
  const [videoPopup, setVideoPopup] = React.useState(false);

  const helpModalImage = (index: number) => {
    setHelpModalImageSrc(helpImages[index]);
    setVideoPopup(true);
  };

  const handlePopupVisibility = (isOpen: boolean) => {
    setVideoPopup(isOpen);
  };

  return (
    <>
      <footer className="site-foot">
        <div className="site-foot-nav container">
          <Row>
            <Col>
              {id && (
                <ul className="pagination">
                  <li className="page-item">
                    <a
                      className={`btn btn-primary btn-lg" ${
                        parseInt(id) === 1 ? "disabled" : ""
                      }`}
                      href={`/kapitel/${parseInt(id) - 1}`}
                    >
                      ←
                    </a>
                  </li>
                  <li className="page-item">
                    <a
                      className={`btn btn-primary btn-lg`}
                      href={`/kapitel/${
                        parseInt(id) === totalPosts ? 1 : parseInt(id) + 1
                      }`}
                    >
                      →
                    </a>
                  </li>
                </ul>
              )}
            </Col>
            <Col>
              <div className="dropdown helpDropdown">
                <Dropdown>
                  <Dropdown.Toggle
                    className="btn btn-secondary commonBtn"
                    id="dropdown-basic"
                  >
                    Hjælp?
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="helpMenu">
                    {/* <Dropdown.Item href="#" onClick={() => helpModalImage(0)}>
                      Hjælp til adgang?
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => helpModalImage(1)}>
                      Hjælp til login?
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => helpModalImage(2)}>
                      Hjælp til password?
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => helpModalImage(3)}>
                      Hjælp til afmelding?
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => helpModalImage(4)}>
                      Hjælp til kort skift?
                    </Dropdown.Item> */}

                    {/* <Dropdown.Item href="/term-service" target="_blank">
                      Abonnementsbetingelser
                    </Dropdown.Item> */}
                    <Dropdown.Item href="/privacy-policy" target="_blank">
                      Privatlivspolitik
                    </Dropdown.Item>
                    {/* <Dropdown.Item href={`mailto: ${BOOK_ACCESS}@tepedu.com`}> */}
                    <Dropdown.Item
                      href={`mailto: jura@tepedu.com?subject=Support vedr jura.tepedu.com`}
                    >
                      Kontakt
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>
      </footer>
      {videoPopup && (
        <HelpVideoPopUp
          handlePopupVisibility={handlePopupVisibility}
          videoSrc={helpModalImageSrc}
        />
      )}
    </>
  );
};

export default Footer;
