// @ts-nocheck
import React, { useEffect, useState, useContext, useRef } from "react";
import Cookies from "universal-cookie";
import {
  API_URL,
  SIGN_IN_SESSION_COOKIE,
  BOOK_SESSION_USER_IP_COOKIE,
  USER_MONTHLY_SIXTY_PLAN_ID,
  USER_PREMIUM_PLAN_ID,
  USER_PRO_PLAN_ID,
  BOOK_ACCESS,
  STRIPE_PK_KEY,
} from "../../utils/constants";
import "../../App.css";
import "../../styles/auth.css";
import Header from "../../components/header";
import { UserContext } from "../../context/UserContext";
import { useLocation, useParams } from "react-router-dom";
import { CHAPTERS } from "../../data/chapters";
import "../../App.css";
import "./kapitel.css";
import TableOfContent from "../../components/TableOfContent/TableOfContent";
import { Card, Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/footer";
import { loadStripe } from "@stripe/stripe-js";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import InnerHTML from "dangerously-set-html-content";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import { Helmet } from "react-helmet";

const NOW_TIME = Date.now();
const View = () => {
  const { userData, userDataLoaded } = useContext(UserContext);
  const [data, setData] = useState<any>();
  const [planType, setPlanType] = useState("");
  const [speechTextEnable, setSpeechTextEnable] = React.useState(false);
  const [userSelectedText, setUserSelectedText] = useState("");
  const [enableTextToPlayPopup, setEnableTextToPlayPopup] = useState("none");
  const [selectedTextXCoor, setSelectedTextXCoor] = useState(0);
  const [selectedTextYCoor, setSelectedTextYCoor] = useState(0);
  const [htmlContent, setHtmlContent] = useState<string>("");
  let { id } = useParams();
  const { hash } = useLocation();

  const myRef = React.useRef<any>();

  let postPath = "";
  if (typeof window !== "undefined") {
    postPath = window.location.pathname;
  }

  useEffect(() => {
    getChapterDataById(id);
    async function fetchHtml() {
      const response = await fetch(`/chapters/${id}.html`);
      const html = await response.text();

      setHtmlContent(html);
    }
    fetchHtml();
  }, [userData, userDataLoaded, id]);

  const getChapterDataById = (id: any) => {
    const chapterData = CHAPTERS.find((chapter) => chapter.id == id);
    if (chapterData) {
      // setTimeout(() => {
      setData(chapterData);
      // }, 2000);
    } else {
      window.location.href = "/";
    }
  };

  const handleSubmit = async (event: any) => {
    // event.preventDefault();
    // const customerId = userData.stripe_id;
    // const email = userData.user_email;
    // await fetch(`${API_URL}/api/create-stripe-checkout`, {
    //   method: "POST",
    //   headers: { "content-type": "application/json" },
    //   body: JSON.stringify({
    //     customerId,
    //     email,
    //     planType,
    //     bookToAccess: BOOK_ACCESS,
    //   }),
    // })
    //   .then(async (response) => response.json())
    //   .then(async (responseJson) => {
    //     const stripePromise = await loadStripe(STRIPE_PK_KEY || '');
    //     const stripe = await stripePromise;
    //     await stripe?.redirectToCheckout({
    //       sessionId: responseJson.body.session.id,
    //     });
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };

  const validateForm = () => {
    return planType.length > 0;
  };

  const enableDisableSpeech = (checked: boolean) => {
    setSpeechTextEnable(checked);
    if (checked) {
    } else {
      if (myRef && myRef.current) {
        myRef.current.pause();
      }
    }
  };

  const selectedText = (event: any) => {
    if (typeof window !== "undefined") {
      //let textToSpeech = "";
      const textToSpeech = window?.getSelection()?.toString();
      if (textToSpeech) {
        setUserSelectedText(textToSpeech);
        setEnableTextToPlayPopup("block");
        setSelectedTextXCoor(event.pageX);
        setSelectedTextYCoor(event.pageY);
        if (speechTextEnable) {
          playText(textToSpeech);
        }
      }
    }
  };

  const playText = (textToSpeech: string) => {
    myRef.current.pause();
    myRef.current.currentTime = 0;
    setEnableTextToPlayPopup("none");
    const url =
      "https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=AIzaSyBpsYZKqwyVctQKamQf4StfhvSWSSz-2lE";
    const data = {
      input: {
        text: textToSpeech,
      },
      voice: {
        languageCode: "da-dk",
        name: "da-DK-Wavenet-A",
        ssmlGender: "FEMALE",
      },
      audioConfig: {
        audioEncoding: "MP3",
      },
    };
    const otherparam = {
      headers: {
        "content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(data),
      method: "POST",
    };
    fetch(url, otherparam)
      .then((data) => {
        return data.json();
      })
      .then((res) => {
        myRef.current.src = "data:audio/wav;base64," + res.audioContent;
        myRef.current.play();
      })
      .catch((error) => {
        //console.state.onError(error);
      });
  };

  const scrollToElementById = () => {
    setTimeout(() => {
      const elementId = hash.split("#")[1];
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000);
  };

  return (
    <>
      <Helmet>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/react/18.0.0/umd/react.production.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/vis/4.21.0/vis.min.js"></script>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js"></script>
        <script src="https://unpkg.com/@popperjs/core@2"></script>
      </Helmet>
      <main className="mainPage">
        <Header />
        <audio ref={myRef} src="" />
        {userDataLoaded && data && data.id ? (
          <>
            {userData.plan_id == USER_PREMIUM_PLAN_ID ||
            userData.plan_id == USER_PRO_PLAN_ID ||
            userData.plan_id == USER_MONTHLY_SIXTY_PLAN_ID ||
            userData.user_subscription_end >= NOW_TIME ||
            data.tags.includes("Free") ? (
              <>
                <figure
                  className="post-feature-image"
                  onLoad={scrollToElementById}
                >
                  <img src={data.image} />
                </figure>
                {htmlContent && (
                  <Container>
                    <Row>
                      <Col md={9}>
                        <section className="post-full-content" onMouseUpCapture={selectedText}>
                          <InnerHTML html={htmlContent} />
                        </section>
                      </Col>
                      <Col md={3}>
                        <TableOfContent />
                      </Col>
                    </Row>
                  </Container>
                )}
              </>
            ) : !userData.user_id ? (
              <Container>
                <Card className="accessCard">
                  <Card.Body className="accessCardBody">
                    <h2 className="whiteClr">
                      Dette kapitel er kun for betalende abonnenter
                    </h2>
                    <p className="font-18">
                      Få adgang her! <a href="/login">Login</a>
                    </p>
                  </Card.Body>
                </Card>
              </Container>
            ) : (
              userData.user_id &&
              userData.plan_id != USER_PREMIUM_PLAN_ID &&
              userData.plan_id != USER_PRO_PLAN_ID &&
              userData.plan_id != USER_MONTHLY_SIXTY_PLAN_ID &&
              userData.user_subscription_end < NOW_TIME && (
                <Container>
                  <Card className="accessCard">
                    <Card.Body className="accessCardBody">
                      <h2 className="whiteClr accessMsg">
                        Dette kapitel er kun for betalende abonnenter
                      </h2>
                      <div className="form-group">
                        <label className="font-size-15">Vælg abonnement</label>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <label
                              data-tip="Monthly 59 DKK"
                              className="margin-right-20"
                            >
                              <input
                                type="radio"
                                name="size"
                                id="monthly_sixty"
                                value="monthly_sixty"
                                onChange={(e) => setPlanType(e.target.value)}
                                required
                              />{" "}
                              Monthly 59 DKK
                            </label>
                            <label
                              data-tip="6 months 290 - DKK one time price"
                              className="margin-right-20"
                            >
                              <input
                                type="radio"
                                name="size"
                                id="six_months_one_time"
                                value="six_months_one_time"
                                required
                                onChange={(e) => setPlanType(e.target.value)}
                              />{" "}
                              6 months 290 - DKK
                            </label>
                            <label
                              data-tip="12 months 390 - DKK one time price"
                              className="margin-right-20"
                            >
                              <input
                                type="radio"
                                name="size"
                                id="twelve_months_one_time"
                                value="twelve_months_one_time"
                                required
                                onChange={(e) => setPlanType(e.target.value)}
                              />{" "}
                              12 months 390 - DKK
                            </label>
                            <label data-tip="24 months 540 - DKK one time price">
                              <input
                                type="radio"
                                name="size"
                                id="twenty_four_months_one_time"
                                value="twenty_four_months_one_time"
                                required
                                onChange={(e) => setPlanType(e.target.value)}
                              />{" "}
                              24 months 540 - DKK
                            </label>
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={!validateForm()}
                          >
                            Opgrader abonnement
                          </button>
                        </form>
                      </div>
                    </Card.Body>
                  </Card>
                </Container>
              )
            )}
          </>
        ) : (
          <div className="loadwrap">
            <div>
              <div className="bounceball"></div>
              <div className="loadText">Indlæser nu!</div>
            </div>
          </div>
        )}
        <div className="switchBtn">
          <BootstrapSwitchButton
            checked={speechTextEnable}
            onstyle="dark"
            offstyle="dark"
            style="border"
            onlabel="Tale"
            offlabel="Ingen tale"
            onChange={enableDisableSpeech}
          />
        </div>
        <Footer />
      </main>
    </>
  );
};

export default View;
